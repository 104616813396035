.profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: var(--color-secondary);
  border-radius: 8px;
  color: var(--color-text);
  box-sizing: border-box;
}

.profile h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--color-gradient-start);
}

.profile-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.profile-picture-section {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.profile-picture-container {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-picture-actions {
  display: flex;
  flex-direction: column;
}

.picture-action-button {
  background-color: var(--color-primary);
  color: var(--color-text);
  border: none;
  padding: 8px 12px;
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.picture-action-button:hover {
  background-color: var(--color-accent);
}

.profile-info {
  flex-grow: 1;
  overflow: hidden;
}

.profile-info h3 {
  margin: 0;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-info p {
  margin: 5px 0 0;
  color: var(--color-text-muted);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--color-text-muted);
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--color-text-muted);
  border-radius: 20px;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 14px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  margin-right: 10px;
}

.submit-button {
  background-color: var(--color-primary);
  color: var(--color-text);
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: var(--color-accent);
}
