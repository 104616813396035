.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: var(--color-text);
}

.home-header {
  text-align: center;
  margin-bottom: 40px;
}

.home-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.tagline {
  font-size: 1.2em;
  color: var(--color-text-muted);
}

.quick-actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary);
  border-radius: 10px;
  padding: 20px;
  text-decoration: none;
  color: var(--color-text);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.action-card svg {
  font-size: 2em;
  margin-bottom: 10px;
}

.recent-playlists h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.playlist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.playlist-card, .create-playlist-card {
  background-color: var(--color-secondary);
  border-radius: 10px;
  padding: 15px;
  transition: transform 0.3s ease;
}

.playlist-card:hover, .create-playlist-card:hover {
  transform: scale(1.05);
}

.playlist-info h3 {
  margin: 0;
  font-size: 1.2em;
}

.playlist-info p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: var(--color-text-muted);
}

.create-playlist-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  text-decoration: none;
}

.create-playlist-card svg {
  font-size: 3em;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .quick-actions {
    grid-template-columns: repeat(2, 1fr);
  }

  .playlist-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
